import React, { useEffect } from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import Portal from 'components/atoms/Portal';
import * as Styled from './index.styles';
import { Confirmable, CreateConfirm } from './Confirm';
import { CloseEdgeButton } from 'components/atoms/Button';

const Modal = (props) => {
  const { title, text, link, button, proceed, icon = '👩🏻‍⚕️', subText, children } = props;

  useEffect(() => {
    // .page-container 고정
    // 모달이 띄워질때, 스크롤 금지
    // fixed 대신 overflow로 수정
    // const ele = document.querySelector('.page-container');
    // ele.style.cssText = `position: fixed; top: -${window.scrollY}px`;
    const ele = document.querySelector('#__next');
    ele.style.cssText = 'overflow: hidden;';

    return () => {
      const scrollY = ele.style.top;
      ele.style.cssText = 'position: ""; top: "";';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, []);

  return (
    <Portal elementId="header">
      <Styled.Blackout
        onClick={(e) => {
          if (e.target === e.currentTarget) proceed(false);
        }}
        d-flex
        justify-content-center
        align-items-center
        className="fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{ background: 'rgba(0,0,0,0.8)' }}
      >
        <LazyMotion features={domAnimation}>
          <Styled.ModalBody
            className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800"
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0, transition: { duration: 0.15 } }}
            transition={{ duration: 0.2 }}
          >
            {title && <div className="title">{title}</div>}
            <div className="content">
              {icon && <Styled.Icon>{icon}</Styled.Icon>}
              <Styled.Title>{text}</Styled.Title>
              {subText && <Styled.Big>{subText}</Styled.Big>}
              {link && (
                <div className="link">
                  <a href={link.url} target="blank">
                    {link.text}
                  </a>
                </div>
              )}
            </div>

            <Styled.ModalButton d-flex>
              <Styled.Button
                className="ok-btn btn"
                onClick={() => {
                  proceed(true);
                }}
              >
                {button?.ok?.text || '확인'}
              </Styled.Button>
              {button?.cancel && (
                <Styled.Button
                  className="cancel-btn btn"
                  onClick={() => {
                    proceed(false);
                  }}
                >
                  {button.cancel.text || '취소'}
                </Styled.Button>
              )}
            </Styled.ModalButton>
          </Styled.ModalBody>
        </LazyMotion>
      </Styled.Blackout>
    </Portal>
  );
};

const BigModal = (props) => {
  const { title, text, link, button, proceed, body } = props;

  useEffect(() => {
    // .page-container 고정
    // 모달이 띄워질때, 스크롤 금지
    // fixed 대신 overflow로 수정
    // const ele = document.querySelector('.page-container');
    // ele.style.cssText = `position: fixed; top: -${window.scrollY}px`;
    const ele = document.querySelector('#__next');
    ele.style.cssText = 'overflow: hidden;';

    return () => {
      const scrollY = ele.style.top;
      ele.style.cssText = 'position: ""; top: "";';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, []);

  return (
    <Portal elementId="modal-root">
      <Styled.Blackout
        onClick={(e) => {
          if (e.target === e.currentTarget) proceed(false);
        }}
        d-flex
        justify-content-center
        align-items-center
        className="fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{ background: 'rgba(0,0,0,0.8)' }}
      >
        <Styled.ModalBody
          style={{ maxWidth: '420px', maxHeight: '900px' }}
          className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800"
        >
          <Styled.ModalWrap>
            <Styled.ModalHeader d-flex align-items-center justify-content-between>
              <div />
              <CloseEdgeButton
                onClick={() => {
                  proceed(false);
                }}
              />
            </Styled.ModalHeader>
          </Styled.ModalWrap>

          {title && <div className="title">{title}</div>}
          <div styled={{ padding: '4rem 0 2rem' }}>
            당첨자에게는 개별 연락드릴 예정이며,
            <br /> 정보 확인 후 경품을 지급합니다.
          </div>
          <div className="content">{body}</div>
        </Styled.ModalBody>
      </Styled.Blackout>
    </Portal>
  );
};

// export default Modal;
export const confirm = (option) =>
  CreateConfirm(Confirmable(Modal))({
    ...option,
  });

// export default Modal;
export const BigPopup = (option) =>
  CreateConfirm(Confirmable(BigModal))({
    ...option,
  });

export default function Index() {
  return <div></div>;
}
