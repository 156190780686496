import React, { useState, useEffect } from 'react';

// export default ({ Component }, props) => {
//   const [show, setShow] = useState(true);
//   let callback = () => {};
//
//   useEffect(() => {
//     if(callback) callback();
//   }, [show]);
//
//   // Component
//   const dismiss = () => {
//     setShow(false);
//     callback = () => {
//       props.dispose();
//     };
//   };
//
//   const cancel = (value) => {
//     setShow(false);
//     callback = () => {
//       props.reject(value);
//     };
//   };
//
//   const proceed = (value) => {
//     setShow(false);
//     callback = () => {
//       props.resolve(value);
//     };
//   };
//
//   return (
//     <Component
//       proceed={proceed}
//       cancel={cancel}
//       dismiss={dismiss}
//       show={show} {...this.props}
//     />
//   )
// }

const Index = (Component) =>
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        show: true,
      };
    }
    dismiss() {
      this.setState(
        {
          show: false,
        },
        () => {
          this.props.dispose();
        }
      );
    }
    cancel(value) {
      this.setState(
        {
          show: false,
        },
        () => {
          this.props.reject(value);
        }
      );
    }
    proceed(value) {
      this.setState(
        {
          show: false,
        },
        () => {
          this.props.resolve(value);
        }
      );
    }
    render() {
      return (
        <Component
          proceed={this.proceed.bind(this)}
          cancel={this.cancel.bind(this)}
          dismiss={this.dismiss.bind(this)}
          show={this.state.show}
          {...this.props}
        />
      );
    }
  };

export default Index;
