// 삭제 될 운명
import React from 'react';
import styled, { css } from 'styled-components';

const flexStyle = {
  'd-flex': 'display: flex;',
  'flex-row': 'flex-direction: row;',
  'flex-column': 'flex-direction: column;',
  'flex-fill': 'flex: 1 1 auto;',
  'flex-grow': 'flex-grow: 1;',
  'justify-content-start': 'justify-content: start;',
  'justify-content-stretch': 'justify-content: stretch;',
  'justify-content-end': 'justify-content: end;',
  'justify-content-center': 'justify-content: center;',
  'justify-content-between': 'justify-content: space-between;',
  'justify-content-around': 'justify-content: space-around;',
  'justify-content-evenly': 'justify-content: space-evenly;',
  'align-items-start': 'align-items: start;',
  'align-items-end': 'align-items: end;',
  'align-items-center': 'align-items: center;',
  'align-items-baseline': 'align-items: baseline;',
  'align-items-stretch': 'align-items: stretch;',
  'align-self-start': 'align-self: start',
  'align-self-end': 'align-self: end',
  'align-self-center': 'align-self: center',
  'align-self-baseline': 'align-self: baseline',
  'align-self-stretch': 'align-self: stretch',
  'flex-wrap': 'flex-wrap: wrap;',
  'flex-nowrap': 'flex-wrap: nowrap;',
  'position-static': 'position: static;',
  'position-absolute': 'position: absolute;',
  'position-relative': 'position: relative;',
  'position-fixed': 'position: fixed;',
  'position-sticky': 'position: sticky;',
};

const textStyle = {
  bold: 'font-weight: bold;',
  'text-center': 'text-align: center;',
  'text-left': 'text-align: left;',
  'text-right': 'text-align: right;',
  'text-underline': 'text-decoration: underline;',
};

const style = {
  containerFluid: 'width: 100%;',
  bdBlack: 'border: 1px solid #000;',
  backgroundRed: 'background-color: red;',
  center: 'justify-content: center; align-items: center;',
  ...textStyle,
  ...flexStyle,
};

const CSS = (props) => {
  let styles = '';

  for (const [key /*value*/] of Object.entries(props)) {
    if (style[key]) {
      styles += `
       ${style[key]}
     `;
    }
  }

  return css`
    ${styles}
  `;
};

const Div = styled.div`
  ${CSS};
`;

const DivComponent = ({ children, ...others }) => <Div {...others}>{children}</Div>;

export default DivComponent;
