import styled from 'styled-components';
import { m } from 'framer-motion';
import Div from 'components/atoms/div';

export const Blackout = styled(Div)`
  width: 100vw;
  max-width: 420px;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 1.4rem;
`;

export const ModalWrap = styled(Div)`
  margin-top: -2rem;
`;

export const ModalHeader = styled(Div)`
  height: 5.8rem;
  .svg-button-close-cross {
    padding: 10px;
    margin: -10px;
    background-position: center;
  }
`;

export const ModalBody = styled(m.div)`
  width: 100%;
  background-color: #fff;
  font-size: 1.4rem;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  padding: 3rem 2rem;
  .title {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .content {
    margin-bottom: 4rem;
    .link {
      margin-top: 1rem;
    }
  }
`;

export const ModalButton = styled(Div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 100%));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  button {
    background: none;
    flex: auto;
    font-weight: bold;
    &.cancel-btn {
      color: #b1b3b2;
    }
    &.ok-btn {
      background-color: #e1ea68;
      border: none;
    }
  }
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  width: 2.6rem;
  height: 2.6rem;
`;

export const Icon = styled.div`
  text-align: center;
  font-size: 7rem;
`;

export const Title = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  white-space: pre-line;
  text-align: center;
`;

export const Big = styled.div`
  font-size: 1.4rem;
  text-align: center;
  white-space: pre-line;
`;

export const Button = styled.button`
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  padding: 1rem 0;
  background-color: #fff;
  border-radius: 2rem;
  border: 1px solid #cccccc;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    color: #aaaaaa;
  }
`;
