import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

const style = {
  containerFluid: 'width: 100%;',
  bdBlack: 'border: 1px solid #000;',
  backgroundRed: 'background-color: red;',
  center: 'justify-content: center; align-items: center;',
};

const CSS = (props) => {
  let styles = '';

  for (const [key] of Object.entries(props)) {
    if (style[key]) {
      styles += `
       ${style[key]}
     `;
    }
  }

  return css`
    ${styles}
  `;
};

const SocialLogoBackgroundCSS = (props) => {
  const socialBgStyle = {
    kakao: 'background-position: 5% center; background-size: auto;',
    naver: 'background-position: 5% center; background-size: auto;',
    facebook: 'background-position: 5% center; background-size: auto;',
  };

  return css`
    ${socialBgStyle[props.social]}
  `;
};

const cssButton = css`
  position: relative;
  width: 100%;
  max-width: 420px;
  height: 6.4rem;
  border-radius: 0.4rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  background-color: #fff;
`;

const cssImageButton = css`
  background-image: url('/assets/images/testImage/test_img_123.png');
  background-size: 5.4rem 5.4rem;
  background-repeat: no-repeat;
  background-position: 2rem center;
`;

const BlackButton = css`
  background-color: #000;
  color: #fff !important;
  font-weight: bold;
`;

const RedButton = css`
  background-color: #dd4124;
  color: #fff !important;
  font-weight: bold;
`;

const BorderWhite = css`
  border: solid 1px #e1e3e2;
`;

const _button = styled.button`
  /* ${CSS}; */
  ${cssButton};
  ${(props) => props.bgBlack && BlackButton}
  ${(props) => props.bgRed && RedButton}
  ${(props) => props.bcWhite && BorderWhite}

  ${(props) =>
    props.disabled &&
    css`
      background-color: #e1e3e2;
    `}

  ${(props) => props.image && cssImageButton}
`;

const _outlineButton = styled.button`
  ${cssButton};
  border: solid 1px #e1e3e2;
  color: #000;
  ${SocialLogoBackgroundCSS}

  &.inactive {
    border-color: #e1e3e2;
  }
  &.active {
    color: #000;
    border-color: #000;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: #b1b3b2;
      border-color: #e1e3e2;
      &.active {
        border-color: #b1b3b2;
      }
    `}
`;

// common button
export const Button = ({ children, disabled, ...others }) => (
  <_button disabled={disabled} {...others}>
    {children}
  </_button>
);

export const OutlineButton = ({ children, disabled, ...others }) => (
  <_outlineButton disabled={disabled} {...others}>
    {children}
  </_outlineButton>
);

// login for social
export const SocialButton = ({ children, type, disabled, social, ...others }) => (
  <_outlineButton
    className={`social-btn-wrap svg-sns-${social}`}
    social={social}
    {...others}
    disabled={disabled}
  >
    {children}
  </_outlineButton>
);

export const CloseEdgeButton = ({
  pathname,
  confirmMsg,
  terminate,
  white,
  replace,
  pickall,
  className,
  onClick,
}) => {
  const router = useRouter();
  return (
    <div
      style={{ boxSizing: 'content-box', filter: `${white && 'brightness(100)'}` }}
      className={`svg-button-close-cross white ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }

        if (terminate) {
          window.close();
        }

        if (confirmMsg && !window.confirm(confirmMsg)) {
          return;
        }

        if (pathname) {
          if (replace) {
            router.replace({
              pathname,
            });
          } else {
            router.push({
              pathname,
            });
          }
        } else {
          router.back();
        }
      }}
    >
      <span className="invisible">close</span>
    </div>
  );
};

export const ImageButton = ({ children, disabled, image, ...others }) => {
  return (
    <_button type="button" disabled={disabled} {...others} image={image}>
      {/* <img src="/assets/images/testImage/test_img_123.png" /> */}
      {children}
    </_button>
  );
};
